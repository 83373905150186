<script lang="ts">
import { ROUTES } from "@/constants/constants";
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "CmpResponsiveMenu",
  setup() {
    const route = useRoute();
    const isMenuOpen = ref(false);
    const localTitle = ref("Home");

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const updateTitle = (title: string) => {
      localTitle.value = title;
      toggleMenu();
    };

    watch(
      () => route.params,
      (newValue) => {
        console.log(newValue);
        const routeName: string = route.name?.toString() || "home";
        localTitle.value = ROUTES[routeName]?.name || "";
      },
      { immediate: true }
    );

    return {
      isMenuOpen,
      localTitle,
      toggleMenu,
      updateTitle,
    };
  },
});
</script>

<template>
  <nav class="menu">
    <div class="menu-logo">
      <!-- Add your logo here -->
      <h1>{{ localTitle }}</h1>
    </div>

    <!-- Burger button for mobile -->
    <button class="menu-toggle" @click="toggleMenu">
      <span :class="{ open: isMenuOpen }"></span>
      <span :class="{ open: isMenuOpen }"></span>
      <span :class="{ open: isMenuOpen }"></span>
    </button>

    <!-- Menu Items -->
    <ul :class="{ 'menu-open': isMenuOpen }">
      <li>
        <v-btn to="/" aria-label="Home" @click="updateTitle('Home')">
          Home
        </v-btn>
      </li>
      <li>
        <v-btn
          to="/courses"
          aria-label="Courses"
          @click="updateTitle('Courses')"
        >
          Courses
        </v-btn>
      </li>
      <!-- <v-btn to="/projects" aria-label="Projects">Projects</v-btn> -->
      <li>
        <v-btn to="/about" aria-label="About" @click="updateTitle('About Us')">
          About Us
        </v-btn>
      </li>
      <li>
        <v-btn
          to="/contact"
          aria-label="Contact Us"
          @click="updateTitle('Contact Us')"
        >
          Contact Us
        </v-btn>
      </li>
    </ul>
  </nav>
</template>

<style scoped>
/* Basic Menu Styles */
.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  position: relative;
}

.menu-logo h1 {
  display: none;
}

/* Menu Links */
.menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.menu ul li {
  margin: 0px 10px;
}

.menu ul li a {
  text-decoration: none;
  font-weight: bold;
}

/* Toggle Button (Burger Icon) */
.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-toggle span {
  display: block;
  width: 100%;
  height: 3px;
  transition: all 0.3s ease;
  background-color: #333333;
}

/* Open Burger Icon (Transform to X) */
.menu-toggle span.open:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu-toggle span.open:nth-child(2) {
  opacity: 0;
}

.menu-toggle span.open:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menu {
    padding: 10px 20px 0 0;
  }

  .menu ul {
    display: none;
    position: fixed;
    top: 175px;
    left: 0;
    margin: 16px;
    right: 0px;
    flex-direction: column;
    align-items: center;
    background-color: #fdfdfd;
    color: #3f3f3f;
    border-radius: 8px;
    box-shadow: 0px 0px 10px -1px gray;
  }

  .menu-logo h1 {
    display: block;
  }

  .menu ul.menu-open {
    display: flex;
  }

  .menu ul li {
    margin: 0px;
    display: flex;
    width: 100%; /* Make li full width */
  }

  .menu ul li a {
    display: flex; /* Make a a flexbox to grow */
    justify-content: left; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    width: 100%; /* Make a take full width */
    height: 100%; /* Make a take full height */
    box-sizing: border-box; /* Ensure padding does not exceed container size */
    padding: 25px;
  }

  .menu-toggle {
    display: flex;
  }
}
</style>

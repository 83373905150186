<script lang="ts">
import { defineComponent } from "vue";
import CmpResponsiveMenu from "./CmpResponsiveMenu.vue";

export default defineComponent({
  name: "CmpHeader",
  components: { CmpResponsiveMenu },
});
</script>

<template>
  <v-container class="app-bar-container rounded">
    <v-toolbar-title class="app-bar-title">
      <router-link to="/">
        <v-img
          :width="420"
          aspect-ratio="16/9"
          cover
          src="@/assets/logo-tb3.png"
        ></v-img>
      </router-link>
      <div class="title-subtext">EDUCATION FOR SCIENCE, TECHNOLOGY, ART</div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <cmp-responsive-menu />
  </v-container>
</template>

<style lang="scss" scoped>
.app-bar-container {
  display: block;
  align-items: center; /* Ensure alignment is centered */
  z-index: 1;
  color: var(--font-color);
  .app-bar-title {
    font-size: 12px; /* Slightly larger for better readability */
    text-align: right;
    max-width: 420px;
    text-align: center;
    margin-top: 15px;

    .title-subtext {
      font-size: 11px;
      text-align: right;
      margin-top: -10;
    }
  }
}
</style>

<template>
  <v-carousel height="600" cycle hide-delimiter-background>
    <v-carousel-item
      v-for="(image, index) in images"
      :key="index"
      :src="image.src"
      :class="image.position ? 'carousel-container' : ''"
      alt="image.description"
      cover
    ></v-carousel-item>
  </v-carousel>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CmpCarousel",
  props: {
    images: Object,
  },
});
</script>

<style lang="scss">
.carousel-container {
  img {
    object-position: top;
  }
}
</style>

<template>
  <v-card
    class="mx-auto explore-card"
    color="surface-variant"
    :lazySrc="image"
    :image="image"
    max-width="340"
  >
    <template v-slot:actions>
      <v-btn :href="link" class="detail-btn" variant="outlined" block>
        <div class="button-title">{{ title }}</div>
      </v-btn>
    </template>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CmpExploreCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.explore-card {
  height: 200px;
}

.detail-btn {
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
  background: rgba(0, 0, 0, 0.32);
  bottom: 0;
  left: 0;
  border: 0;
  position: absolute;
  color: #fff;
  transition: all 0.3s cubic-bezier(0, 0.81, 1, 0.97);
  align-items: baseline;

  .content {
    display: block;
  }

  .button-title {
    width: 100%;
    line-height: var(--spacer-20);
    padding: var(--spacer-8);
  }
}

.explore-card:hover {
  .detail-btn {
    height: 100%;
  }
}
</style>

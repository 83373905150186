// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";
// import colors from "vuetify/util/colors";

export default createVuetify({});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

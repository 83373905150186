import { defineStore } from "pinia";
import api from "@/services/api";
import { ref } from "vue";

interface AuthState {
  token: string;
  isAuthenticated: boolean;
}

export const useAuthStore = defineStore("auth", () => {
  const state = ref<AuthState>({
    token: localStorage.getItem("token") || "",
    isAuthenticated: !!localStorage.getItem("token"),
  });

  const login = async (username: string, password: string) => {
    try {
      const response = await api.post("/login", { username, password });
      state.value.token = response.data.token;
      localStorage.setItem("token", response.data.token);
      state.value.isAuthenticated = true;
    } catch (error) {
      console.error("Login failed:", error);
      state.value.isAuthenticated = false;
    }
  };

  const logout = () => {
    state.value.token = "";
    localStorage.removeItem("token");
    state.value.isAuthenticated = false;
  };

  return {
    ...state.value,
    login,
    logout,
  };
});

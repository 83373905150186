import { COMMON_KEY_VALUE } from "@/types/common";

export const CATEGORIES = {
  WEB_DEVELOPMENT_FE: {
    CODE: "WEB_DEV_FE",
    TYPE: "WEB_DEVELOPMENT_FE",
    DESCRIPTION:
      "JavaScript, Vue.js, React.js, Angular.js, HTML, CSS, jQuery, JSP",
  },
  WEB_DEVELOPMENT_BE: {
    CODE: "WEB_DEV_BE",
    TYPE: "WEB_DEVELOPMENT_BE",
    DESCRIPTION:
      "Java, Python, C, C++, SQL, PHP, NodeJs, NuxtJs, NextJs, ExpressJs",
  },
  CLOUD_DEVOPS: {
    CODE: "CLO-DEV",
    TYPE: "CLOUD_DEVOPS",
    DESCRIPTION: "AWS, Azure",
  },
  CYBER_SECURITY: {
    CODE: "CYB-SEC",
    TYPE: "CYBER_SECURITY",
    DESCRIPTION:
      "Cryptography, Incident Response, Cybersecurity Risk Management, Cybersecurity Compliance and Governance ",
  },
  DATABASE: {
    CODE: "DAT_BAS",
    TYPE: "DATABASES",
    DESCRIPTION: "PostgreSQL, Oracle, MongoDb, MySql",
  },
  AIML_ROBOTICS: {
    CODE: "AIM_ROB",
    TYPE: "AIML_ROBOTICS",
    DESCRIPTION: "AI-ML, Scratch, Lego Mindstorms, Spike Prime",
  },
  GENERAL_PROGRAMMING: {
    CODE: "GEN_PRO",
    TYPE: "GENERAL_PROGRAMMING",
    DESCRIPTION:
      "Introduction to programming, Time Management, Introduction to Cybersecurity",
  },
};

export const ROUTES = {
  home: { path: "", route: "home", name: "Home" },
  courses: { path: "/courses", route: "courses", name: "Courses" },
  about: { path: "/about", route: "about", name: "About" },
  login: { path: "/login", route: "login", name: "Login" },
  contact: { path: "/contact", route: "contact", name: "Contact" },
  course: { path: "/course", route: "course", name: "Course" },
  projects: { path: "/projects", route: "projects", name: "Projects" },
  regulations: {
    path: "/regulations",
    route: "regulations",
    name: "Regulations",
  },
  services: { path: "/services", route: "services", name: "Services" },
  team: { path: "/team", route: "team", name: "Team" },
} as COMMON_KEY_VALUE;
// Connections Between Teachers:
// Bekir and Debashree: They both specialize in frontend development, general programming, and skills training.
// Bekir and Manoj: Both have expertise in JavaScript and related frontend technologies.
// Manoj and Hakan: Both have expertise in databases.
// Manoj and Debashree: They have overlapping skills in programming and web technologies.
// Debashree and Hakan: Both have backend development expertise.

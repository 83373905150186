import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cmp_header = _resolveComponent("cmp-header")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_cmp_footer = _resolveComponent("cmp-footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        height: "200",
        app: "",
        "scroll-behavior": "elevate",
        "scroll-threshold": "500",
        class: "app-bar"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_cmp_header)
        ]),
        _: 1
      }),
      _createVNode(_component_v_main, { class: "v-main-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }),
      _createVNode(_component_cmp_footer, { class: "cmp-footer-container" })
    ]),
    _: 1
  }))
}
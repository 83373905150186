<script lang="ts">
import { defineComponent } from "vue"; // , onMounted, ref
import CmpHeader from "@/components/CmpHeader.vue";
import CmpFooter from "@/components/CmpFooter.vue";

export default defineComponent({
  name: "App",
  components: {
    CmpHeader,
    CmpFooter,
  },
  data() {
    return {
      videoPath: require("@/assets/video/vid_bg.mp4"),
      videoType: "video/mp4",
    };
  },
  setup() {
    // const videoPlayer = ref<HTMLVideoElement | null>(null);
    // onMounted(() => {
    //   if (videoPlayer.value) {
    //     videoPlayer.value.play().catch((error) => {
    //       console.error("Video play failed:", error);
    //     });
    //   }
    // });
    // return { videoPlayer };
  },
});
</script>

<template>
  <v-app>
    <!-- Toolbar section -->
    <v-app-bar
      height="200"
      app
      scroll-behavior="elevate"
      scroll-threshold="500"
      class="app-bar"
    >
      <cmp-header></cmp-header>
    </v-app-bar>
    <!-- Main content outlet -->
    <v-main class="v-main-container">
      <router-view />
    </v-main>

    <cmp-footer class="cmp-footer-container"></cmp-footer>

    <!-- <video ref="videoPlayer" autoplay muted loop>
      <source :src="videoPath" :type="videoType" />
      Your browser does not support the video tag.
    </video> -->
  </v-app>
</template>

<style lang="scss" scoped>
body {
  font-family: sans-serif;
}
.app-bar {
  background-color: rgba(255, 255, 255, 0.611);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
}
.v-main-container {
  z-index: 1;
}
.cmp-footer-container {
  max-height: 150px;
  min-height: 150px;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.611);
  -webkit-backdrop-filter: saturate(100%) blur(10px);
  backdrop-filter: saturate(100%) blur(10px);
}
// video {
//   opacity: 0.06;
//   position: fixed;
//   top: 0px;
//   left: 0px;
//   z-index: 0;
// }
</style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_carousel_item = _resolveComponent("v-carousel-item")!
  const _component_v_carousel = _resolveComponent("v-carousel")!

  return (_openBlock(), _createBlock(_component_v_carousel, {
    height: "600",
    cycle: "",
    "hide-delimiter-background": ""
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
        return (_openBlock(), _createBlock(_component_v_carousel_item, {
          key: index,
          src: image.src,
          class: _normalizeClass(image.position ? 'carousel-container' : ''),
          alt: "image.description",
          cover: ""
        }, null, 8, ["src", "class"]))
      }), 128))
    ]),
    _: 1
  }))
}
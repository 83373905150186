// src/stores/courses.ts
import { defineStore } from "pinia";
import { dtCourseCards, dtCourses } from "./data/dtCourses";
import { Course } from "@/types/course";

export const useCoursesStore = defineStore("courses", {
  state: () => ({
    courseCards: dtCourseCards,
    courses: dtCourses as Course[],
  }),

  actions: {
    findCourseById(id: number) {
      return this.courses.find((course) => course.id === id) || null;
    },
  },
});

import "./plugins/axios";
import { createApp } from "vue";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import apiClient from "@/plugins/axios";

const app = createApp(App);
const pinia = createPinia();
app.config.globalProperties.$axios = apiClient;

loadFonts();

app.use(createPinia()).use(router).use(pinia).use(vuetify).mount("#app");

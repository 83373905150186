<template>
  <cmp-carousel :images="carouselImages"></cmp-carousel>

  <v-container class="upgrade-yourself-container">
    <v-card class="upgrade-yourself-card" variant="flat">
      <v-card-title>
        <h1 class="-title-rounded-h">
          <span>Upgrade yourself</span>
        </h1>
      </v-card-title>
      <v-card-text class="content">
        <p>
          Anywhere, anytime. Start learning today! And Get an Exceptional
          Education Experience.
        </p>
        <!-- <p>
          At TECHBRIDGE, we're committed to empowering you with the knowledge
          and skills you need to thrive in today's digital landscape. Whether
          you're a beginner looking to kickstart your IT journey or a seasoned
          professional aiming to stay ahead of the curve, we've got you covered.
        </p> -->
      </v-card-text>
    </v-card>
  </v-container>

  <v-container>
    <h1 class="text-h4 py-4 -title-1">Popular Courses</h1>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
        lg="3"
        v-for="course in courses"
        :key="course.title"
      >
        <cmp-info-card
          :image="course.image"
          :title="course.title"
          :subtitle="course.subtitle"
          :text="course.text"
          :cssClass="course.color"
          :courseId="course.courseId"
        />
      </v-col>
    </v-row>
  </v-container>

  <v-container>
    <h1 class="text-h4 py-4 -title-1">Explore More Courses</h1>
    <!-- 
      Bekir: Javascript, VueJs, ReactJs, Flutter, JQuery, Introduction to programming, Time Management
      Meric: Cyber Security,
      Manoj: AWS, AngularJs, MongoDb,
      Hakan: PostgreSql, Oracle, Oracle forms, 
      Debashree: Java, JSP, HTML, CSS, Python, C, C++, Scratch, Lego Mindstorms, Spike Prime, AI- ML (Artificial Intelligence- Machine Learning) -->
    <v-card>
      <v-tabs v-model="tab" align-tabs="start" color="deep-purple-accent-4">
        <v-tab href="javascript:void(0)" value="tab1">Web Development</v-tab>
        <v-tab href="javascript:void(0)" value="tab2">Cloud and Dev-Ops</v-tab>
        <v-tab href="javascript:void(0)" value="tab3">Cyber Security</v-tab>
        <v-tab href="javascript:void(0)" value="tab4">Databases</v-tab>
        <v-tab href="javascript:void(0)" value="tab5">AI/ML and Robotics</v-tab>
        <v-tab href="javascript:void(0)" value="tab6"
          >General Programming and Skills</v-tab
        >
      </v-tabs>

      <v-card-text>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item v-for="n in 6" :key="n" :value="`tab${n}`">
            <v-container fluid>
              <v-row>
                <v-col v-for="i in 6" :key="i" cols="12" md="4">
                  <cmp-explore-card
                    title="Architect"
                    description="Nulla lobortis nunc vitae nisi semper semper."
                    :image="`https://picsum.photos/500/300?image=${
                      i * n * 5 + 10
                    }`"
                    link="#"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-tabs-window-item>
        </v-tabs-window>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CmpCarousel from "../components/CmpCarousel.vue";
import CmpInfoCard from "../components/CmpInfoCard.vue";
import CmpExploreCard from "../components/CmpExploreCard.vue";
import { useCoursesStore } from "@/stores/courses";

export default defineComponent({
  name: "HomeView",
  components: {
    CmpCarousel,
    CmpInfoCard,
    CmpExploreCard,
  },
  data() {
    return {
      tab: null,
      carouselImages: [
        {
          src: require("@/assets/homeView/tshirt-1.png"),
          description: "Slide 1",
        },
        {
          src: require("@/assets/homeView/tshirt-2.png"),
          description: "Slide 2",
        },
        {
          src: require("@/assets/homeView/tshirt-3.png"),
          description: "Slide 3",
          position: "top",
        },
      ],
      courses: useCoursesStore().courseCards,
    };
  },
});
</script>

<style scoped lang="scss">
h1 {
  padding: 12px 0;
}
.courses-card-container {
  display: flex;
  flex-wrap: wrap;
}
.upgrade-yourself-container {
  padding: var(--spacer-16) 0;
  .content {
    font-size: var(--spacer-24);
  }
}
</style>

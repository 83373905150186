<template>
  <v-container class="pa-0">
    <v-card class="info-card">
      <v-img :src="image" height="200px" cover></v-img>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-subtitle>{{ subtitle }}</v-card-subtitle>
      <v-card-text class="info-card-text">
        {{ text }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          variant="text"
          block
          :border="true"
          :class="cssClass"
          :to="`/course/${courseId}`"
        >
          detail
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CmpInfoCard",
  props: {
    image: String,
    title: String,
    subtitle: String,
    text: String,
    cssClass: String,
    courseId: Number,
  },
});
</script>

<style lang="scss">
.info-card {
  height: 415px;
  display: flex;
  flex-direction: column;

  .info-card-text {
    height: 73px;
    overflow: auto;
  }
  img {
    object-position: center;
  }
}
</style>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-752d4c56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "mx-auto explore-card",
    color: "surface-variant",
    lazySrc: _ctx.image,
    image: _ctx.image,
    "max-width": "340"
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_v_btn, {
        href: _ctx.link,
        class: "detail-btn",
        variant: "outlined",
        block: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1)
        ]),
        _: 1
      }, 8, ["href"])
    ]),
    _: 1
  }, 8, ["lazySrc", "image"]))
}
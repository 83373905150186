import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, { class: "info-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_img, {
            src: _ctx.image,
            height: "200px",
            cover: ""
          }, null, 8, ["src"]),
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.subtitle), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, { class: "info-card-text" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                variant: "text",
                block: "",
                border: true,
                class: _normalizeClass(_ctx.cssClass),
                to: `/course/${_ctx.courseId}`
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" detail ")
                ]),
                _: 1
              }, 8, ["class", "to"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
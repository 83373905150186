export const dtCourseCards = [
  {
    title: "Web App",
    subtitle: "Web application development.",
    text: "Full-stack web application development course.",
    image: require("@/assets/cards/web-app.jpg"),
    color: "-btn-primary-bg",
    courseId: 401,
  },
  {
    title: "Node.js",
    subtitle: "JavaScript runtime built on Chrome's V8 JavaScript engine.",
    text: "Node.js explanation here...",
    image: require("@/assets/cards/nodejs.jpg"),
    color: "-btn-primary-bg",
    courseId: 501,
  },
  {
    title: "JavaScript",
    subtitle: "Versatile, high-level programming language.",
    text: "JavaScript explanation here...",
    image: require("@/assets/cards/javascript.jpg"),
    color: "-btn-primary-bg",
    courseId: 102,
  },
  {
    title: "TypeScript",
    subtitle: "Strict syntactical superset of JavaScript.",
    text: "TypeScript explanation here...",
    image: require("@/assets/cards/typescript.jpg"),
    color: "-btn-primary-bg",
    courseId: 201,
  },
  {
    title: "Flutter & Dart",
    subtitle: `Flutter is a framework, while Dart is 
    the programming language`,
    text: "Flutter explanation here...",
    image: require("@/assets/cards/flutter.jpg"),
    color: "-btn-primary-bg",
    courseId: 301,
  },
];

export const dtCourses = [
  {
    id: 401,
    title: "Introduction to Web Development",
    instructor: "John Doe",
    description: "This course will teach you about HTML, CSS, and JavaScript.",
    image: require("@/assets/courses_images/web-development.jpg"),
    content: [
      {
        title: "Module 1: Understanding HTML",
        duration: "3 Hours",
        completed: false,
        explanation: `This module introduces the basics of HTML, 
        the foundational language used to create web pages. 
        Students will learn about the structure of HTML documents, 
        the function of various HTML tags, and the concept of 
        the Document Object Model (DOM).`,
      },
      {
        title: "Module 2: Styling with CSS",
        duration: "2 Hours",
        completed: false,
        explanation: `This module introduces Cascading Style Sheets 
        (CSS), the styling language used to design and enhance the 
        visual presentation of web pages. Students will learn how to 
        connect the structure of HTML with the aesthetics provided by CSS.`,
      },
      {
        title: "Module 3: JavaScript Basics",
        duration: "4 Hours",
        completed: false,
        explanation: `This module introduces JavaScript, the powerful 
        scripting language used to create dynamic content on websites. 
        Students will learn the fundamentals of JavaScript programming, 
        including syntax, control structures, and basic functionalities 
        that enhance user interaction.`,
      },
      {
        title: "Module 4: Advanced JavaScript",
        duration: "5 Hours",
        completed: false,
        explanation: `Building on the basics, this module dives deeper 
        into more sophisticated aspects of JavaScript, enhancing students’ 
        abilities to write efficient, effective, and scalable code. 
        This part of the course covers advanced concepts such as asynchronous 
        programming, advanced data handling, and modern JavaScript features.`,
      },
      {
        title: "Module 6: Working with APIs",
        duration: "2 Hours",
        completed: false,
        explanation: `This module focuses on understanding and utilizing web 
        APIs (Application Programming Interfaces), which are critical for 
        modern web development. Students will learn how to interact with external 
        services and process data dynamically to enhance web applications.`,
      },
      {
        title: "Module 7: Building a Full-Stack App",
        duration: "8 Hours",
        completed: false,
        explanation: `This comprehensive module brings together front-end and 
        back-end technologies, providing students with the knowledge and skills 
        needed to build complete web applications. Students will integrate HTML, 
        CSS, JavaScript, and a server-side language to create a dynamic, 
        database-driven web application.`,
      },
    ],
  },
  {
    id: 501,
    title: "Node.js Development",
    instructor: "Jane Smith",
    description:
      "This course will teach you about Node.js, a powerful JavaScript runtime built on Chrome's V8 JavaScript engine.",
    image: require("@/assets/cards/nodejs.jpg"),
    content: [
      {
        title: "Module 1: Introduction to Node.js",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module introduces Node.js, explaining its core concepts, architecture, and how it differs from traditional server-side technologies. Students will learn about the event-driven, non-blocking I/O model that makes Node.js lightweight and efficient.",
      },
      {
        title: "Module 2: Setting Up the Environment",
        duration: "1 Hour",
        completed: false,
        explanation:
          "This module covers the installation and configuration of Node.js and npm (Node Package Manager). Students will set up their development environment and learn how to manage packages and dependencies for their Node.js applications.",
      },
      {
        title: "Module 3: Node.js Core Modules",
        duration: "3 Hours",
        completed: false,
        explanation:
          "In this module, students will explore core Node.js modules such as fs, path, http, and os. They will learn how to use these modules to handle file operations, create HTTP servers, and perform other essential tasks.",
      },
      {
        title: "Module 4: Asynchronous Programming",
        duration: "3 Hours",
        completed: false,
        explanation:
          "This module dives into asynchronous programming in Node.js, covering callbacks, promises, and async/await. Students will learn how to handle asynchronous operations effectively to build responsive and performant applications.",
      },
      {
        title: "Module 5: Working with Express.js",
        duration: "4 Hours",
        completed: false,
        explanation:
          "Students will be introduced to Express.js, a minimal and flexible Node.js web application framework. This module covers setting up Express.js, routing, middleware, and building RESTful APIs.",
      },
      {
        title: "Module 6: Database Integration with MongoDB",
        duration: "3 Hours",
        completed: false,
        explanation:
          "This module focuses on integrating Node.js applications with MongoDB, a popular NoSQL database. Students will learn how to perform CRUD operations, design database schemas, and use Mongoose for object data modeling.",
      },
      {
        title: "Module 7: Real-time Communication with WebSockets",
        duration: "2 Hours",
        completed: false,
        explanation:
          "Students will learn how to implement real-time communication in their Node.js applications using WebSockets. This module covers the basics of WebSockets, setting up a WebSocket server, and building real-time features such as chat applications.",
      },
      {
        title: "Module 8: Building a Complete Node.js Application",
        duration: "6 Hours",
        completed: false,
        explanation:
          "In this comprehensive module, students will apply their knowledge to build a full-fledged Node.js application. They will integrate various components such as Express.js, MongoDB, and WebSockets to create a dynamic, real-time web application.",
      },
    ],
  },
  {
    id: 102,
    title: "JavaScript Mastery",
    instructor: "G. Kaplan",
    description: `This course will teach you JavaScript from the ground up, covering both 
      fundamental and advanced topics. JavaScript is a versatile, high-level 
      programming language that is one of the core technologies of the World 
      Wide Web, alongside HTML and CSS. Over 97% of websites use JavaScript 
      on the client side for web page behavior, often incorporating third-party 
      libraries. It was originally developed by Netscape as a means to add 
      dynamic and interactive elements to websites.`,
    image: require("@/assets/cards/javascript.jpg"),
    content: [
      {
        title: "Module 1: Introduction to JavaScript",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module provides an overview of JavaScript, its history, and its role in web development. Students will learn about the basics of the language, including syntax, data types, and basic operations.",
      },
      {
        title: "Module 2: Variables and Data Types",
        duration: "3 Hours",
        completed: false,
        explanation:
          "In this module, students will delve into JavaScript variables, understanding var, let, and const. They will explore different data types such as strings, numbers, booleans, arrays, and objects.",
      },
      {
        title: "Module 3: Functions and Scope",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn about defining and invoking functions, understanding parameters and return values, and exploring the concept of scope and closures in JavaScript.",
      },
      {
        title: "Module 4: Control Structures",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module covers control structures in JavaScript, including conditionals (if-else statements), loops (for, while, do-while), and switch statements to control the flow of the program.",
      },
      {
        title: "Module 5: Object-Oriented JavaScript",
        duration: "4 Hours",
        completed: false,
        explanation:
          "Students will be introduced to object-oriented programming in JavaScript, learning about objects, prototypes, classes, and inheritance. This module also covers the importance of 'this' keyword and how to use it effectively.",
      },
      {
        title: "Module 6: Asynchronous JavaScript",
        duration: "4 Hours",
        completed: false,
        explanation:
          "This module dives into asynchronous programming in JavaScript, covering callbacks, promises, and async/await. Students will learn how to handle asynchronous operations to build responsive and efficient applications.",
      },
      {
        title: "Module 7: Working with the DOM",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn how to interact with the Document Object Model (DOM) using JavaScript. This module covers selecting and manipulating DOM elements, handling events, and updating the UI dynamically.",
      },
      {
        title: "Module 8: Advanced JavaScript Concepts",
        duration: "4 Hours",
        completed: false,
        explanation:
          "This module covers advanced JavaScript concepts such as closures, higher-order functions, the module pattern, and ES6+ features like destructuring, spread/rest operators, and template literals.",
      },
      {
        title: "Module 9: Debugging and Testing",
        duration: "2 Hours",
        completed: false,
        explanation:
          "Students will learn about debugging techniques in JavaScript using browser developer tools, and how to write and run tests using frameworks like Jest to ensure their code is robust and error-free.",
      },
      {
        title: "Module 10: Building a JavaScript Project",
        duration: "6 Hours",
        completed: false,
        explanation:
          "In this comprehensive module, students will apply their knowledge to build a complete JavaScript project. They will use various JavaScript concepts and techniques learned throughout the course to create a dynamic, interactive web application.",
      },
    ],
  },
  {
    id: 201,
    title: "TypeScript Mastery",
    instructor: "G. Kaplan",
    description: `This course will teach you TypeScript from the ground up, 
    covering both fundamental and advanced topics. TypeScript is a programming 
    language developed and maintained by Microsoft. It is a strict syntactical 
    superset of JavaScript, which means that any valid JavaScript code is also 
    valid TypeScript code. TypeScript adds optional static typing to JavaScript, 
    which can help catch errors early through a type system and to support IDE 
    tools for navigation, refactoring, and autocompletion.`,
    image: require("@/assets/cards/typescript.jpg"),
    content: [
      {
        title: "Module 1: Introduction to TypeScript",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module provides an overview of TypeScript, its features, and benefits over JavaScript. Students will learn about the TypeScript compiler and how to set up a TypeScript development environment.",
      },
      {
        title: "Module 2: Basic Types and Variables",
        duration: "3 Hours",
        completed: false,
        explanation:
          "In this module, students will learn about TypeScript's type system. They will explore basic types such as string, number, boolean, arrays, tuples, and enums, and understand how to declare and use typed variables.",
      },
      {
        title: "Module 3: Functions and Interfaces",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn how to define functions with type annotations for parameters and return values. This module also covers interfaces, which are used to define the shape of objects, and how to use them to enforce type safety.",
      },
      {
        title: "Module 4: Classes and Inheritance",
        duration: "3 Hours",
        completed: false,
        explanation:
          "This module introduces object-oriented programming concepts in TypeScript. Students will learn about classes, constructors, properties, methods, inheritance, and access modifiers (public, private, protected).",
      },
      {
        title: "Module 5: Advanced Types",
        duration: "4 Hours",
        completed: false,
        explanation:
          "In this module, students will dive deeper into TypeScript's advanced types such as union types, intersection types, type aliases, and type guards. They will also learn about type assertions and the `unknown` type.",
      },
      {
        title: "Module 6: Generics",
        duration: "3 Hours",
        completed: false,
        explanation:
          "This module covers the concept of generics in TypeScript, which allows for the creation of reusable components that work with any data type. Students will learn how to write generic functions, classes, and interfaces.",
      },
      {
        title: "Module 7: Decorators",
        duration: "2 Hours",
        completed: false,
        explanation:
          "Students will learn about decorators, a special type of declaration that can be attached to a class, method, accessor, property, or parameter. This module covers how to create and use decorators to add meta-programming capabilities to code.",
      },
      {
        title: "Module 8: Working with Modules",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module covers how to organize TypeScript code using modules. Students will learn about ES6 module syntax, importing and exporting modules, and how to structure large TypeScript projects efficiently.",
      },
      {
        title: "Module 9: Integrating TypeScript with JavaScript Projects",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn how to gradually integrate TypeScript into existing JavaScript projects. This module covers configuration, migrating JavaScript files to TypeScript, and dealing with third-party JavaScript libraries.",
      },
      {
        title: "Module 10: Building a TypeScript Project",
        duration: "6 Hours",
        completed: false,
        explanation:
          "In this comprehensive module, students will apply their knowledge to build a complete TypeScript project. They will use various TypeScript concepts and techniques learned throughout the course to create a robust, type-safe application.",
      },
    ],
  },
  {
    id: 301,
    title: "Flutter & Dart Development",
    instructor: "G. Kaplan",
    description: `Flutter and Dart are two distinct but closely related 
    technologies used in modern software development, particularly for 
    building mobile applications. Flutter is a framework, while Dart is 
    the programming language used to develop applications with that framework. 
    This course will teach you Flutter and Dart from the ground up, covering 
    both fundamental and advanced topics to help you build beautiful and 
    performant mobile applications.`,
    image: require("@/assets/cards/flutter.jpg"),
    content: [
      {
        title: "Module 1: Introduction to Dart",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module provides an overview of Dart, the programming language used for Flutter development. Students will learn about Dart's syntax, data types, variables, and basic operations.",
      },
      {
        title: "Module 2: Control Flow and Functions in Dart",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn about control flow statements in Dart such as if-else, switch, and loops. This module also covers defining and using functions, including parameters, return types, and scope.",
      },
      {
        title: "Module 3: Object-Oriented Programming with Dart",
        duration: "4 Hours",
        completed: false,
        explanation:
          "This module introduces object-oriented programming concepts in Dart. Students will learn about classes, objects, inheritance, polymorphism, and encapsulation, along with constructors and methods.",
      },
      {
        title: "Module 4: Introduction to Flutter",
        duration: "2 Hours",
        completed: false,
        explanation:
          "Students will be introduced to Flutter, Google's UI toolkit for building natively compiled applications for mobile, web, and desktop from a single codebase. This module covers setting up the development environment and creating a basic Flutter app.",
      },
      {
        title: "Module 5: Flutter Widgets",
        duration: "3 Hours",
        completed: false,
        explanation:
          "This module dives into Flutter widgets, the building blocks of a Flutter app. Students will learn about stateless and stateful widgets, widget trees, and how to create and style widgets to build a responsive UI.",
      },
      {
        title: "Module 6: Layouts and Navigation in Flutter",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn how to build complex layouts using Flutter's layout widgets like Column, Row, Stack, and GridView. This module also covers navigation and routing to enable multi-screen applications.",
      },
      {
        title: "Module 7: State Management",
        duration: "4 Hours",
        completed: false,
        explanation:
          "This module covers different state management techniques in Flutter, including setState, InheritedWidget, Provider, Riverpod, and Bloc. Students will learn how to manage and share state efficiently across their applications.",
      },
      {
        title: "Module 8: Networking and Data Handling",
        duration: "3 Hours",
        completed: false,
        explanation:
          "Students will learn how to make HTTP requests, handle JSON data, and interact with RESTful APIs in Flutter. This module also covers managing data using local storage solutions like SharedPreferences and SQLite.",
      },
      {
        title: "Module 9: Testing and Debugging Flutter Apps",
        duration: "2 Hours",
        completed: false,
        explanation:
          "This module focuses on testing and debugging Flutter applications. Students will learn about writing unit tests, widget tests, and integration tests, as well as using Flutter's debugging tools to troubleshoot issues.",
      },
      {
        title: "Module 10: Building a Complete Flutter Application",
        duration: "6 Hours",
        completed: false,
        explanation:
          "In this comprehensive module, students will apply their knowledge to build a complete Flutter application from scratch. They will use various Flutter and Dart concepts and techniques learned throughout the course to create a fully functional, visually appealing mobile app.",
      },
    ],
  },
];

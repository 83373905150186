import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f5f420d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = { class: "menu-logo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.localTitle), 1)
    ]),
    _createElementVNode("button", {
      class: "menu-toggle",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass({ open: _ctx.isMenuOpen })
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass({ open: _ctx.isMenuOpen })
      }, null, 2),
      _createElementVNode("span", {
        class: _normalizeClass({ open: _ctx.isMenuOpen })
      }, null, 2)
    ]),
    _createElementVNode("ul", {
      class: _normalizeClass({ 'menu-open': _ctx.isMenuOpen })
    }, [
      _createElementVNode("li", null, [
        _createVNode(_component_v_btn, {
          to: "/",
          "aria-label": "Home",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateTitle('Home')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Home ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_v_btn, {
          to: "/courses",
          "aria-label": "Courses",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateTitle('Courses')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Courses ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_v_btn, {
          to: "/about",
          "aria-label": "About",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateTitle('About Us')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" About Us ")
          ]),
          _: 1
        })
      ]),
      _createElementVNode("li", null, [
        _createVNode(_component_v_btn, {
          to: "/contact",
          "aria-label": "Contact Us",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateTitle('Contact Us')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Contact Us ")
          ]),
          _: 1
        })
      ])
    ], 2)
  ]))
}
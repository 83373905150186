<template>
  <v-footer class="footer-container">
    <v-row justify="center" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.key"
        :to="link.path"
        class="mx-2"
        rounded="xl"
        variant="text"
      >
        {{ link.key }}
      </v-btn>
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>TechBridge</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CmpFooter",
  data: () => ({
    links: [
      { key: "Home", path: "/" },
      { key: "About Us", path: "/about" },
      // { key: "Team", path: "/team" },
      { key: "Services", path: "/services" },
      { key: "Regulations", path: "/regulations" },
      { key: "Contact Us", path: "/contact" },
    ],
  }),
});
</script>

<style lang="scss" scoped>
.footer-container {
  color: var(--secondary-font-color);
  border-top: solid 1px lightgrey;
}
</style>
